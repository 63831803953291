define("backend/pods/components/hm-model/fieldset-profile/-company-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    tagName: 'fieldset',
    buffers: Ember.computed.alias('model.buffers'),
    // ------------------------ ORGANIZATION LIST --------------------------------
    keywords: Ember.inject.service(),
    // -------------------------- GENDER LIST ------------------------------------
    gender: Ember.computed(function () {
      return [Ember.Object.create({
        id: 'M',
        displayName: 'Male'
      }), Ember.Object.create({
        id: 'F',
        displayName: 'Female'
      }), Ember.Object.create({
        id: 'D',
        displayName: 'Diverse'
      })];
    }),
    actions: {
      createOrganization: function createOrganization(name) {
        var _this = this;

        this.get('store').createRecord('organization', {
          name: name
        });
        Ember.run.schedule('afterRender', function () {
          return _this.set('buffers.selected.organization', name);
        });
      }
    }
  });

  _exports.default = _default;
});