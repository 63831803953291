define("backend/pods/lead/orders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5YEsCBOu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"py-8\"],[8],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"hm-models/list/table-readonly\",null,[[\"data\",\"isLoading\",\"headerComponent\",\"rowComponent\",\"tableColumns\",\"defaultClientSort\",\"title\",\"showPagination\",\"showFooter\"],[[23,0,[\"model\",\"orders\"]],[23,0,[\"model\",\"orders\",\"isLoading\"]],[28,\"component\",[\"hm-models/list/header-contracts\"],null],[28,\"component\",[\"hm-models/list/orders/row\"],null],[23,0,[\"tableColumns\"]],[23,0,[\"clientSort\"]],\"Device Orders\",false,false]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"py-16\"],[8],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"hm-models/list/table-readonly\",null,[[\"data\",\"isLoading\",\"headerComponent\",\"rowComponent\",\"tableColumns\",\"defaultClientSort\",\"title\",\"showPagination\",\"showFooter\"],[[23,0,[\"model\",\"materialOrders\"]],[23,0,[\"model\",\"materialOrders\",\"isLoading\"]],[28,\"component\",[\"hm-models/list/header-contracts\"],null],[28,\"component\",[\"hm-models/list/orders/material-order-row\"],null],[23,0,[\"tableColumns\"]],[23,0,[\"clientSort\"]],\"Material Orders\",false,false]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/lead/orders/template.hbs"
    }
  });

  _exports.default = _default;
});