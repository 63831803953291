define("backend/components/create-support-case", ["exports", "backend/components/route-component"], function (_exports, _routeComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SupportCase = Ember.Object.extend({
    owner: null,
    device: null,
    status: null,
    tracking: null,
    return_tracking: null,
    replacement_device: false,
    stk: false,
    billing_task: false,
    unknown_device: false,
    notes: null,
    contactName: null,
    contactPhone: null,
    contactEmail: null,
    files: Ember.computed(function () {
      return [];
    }),
    save: function save(store) {
      var owner = this.get('owner');
      var device = this.get('device');
      var status = Number(this.get('status.id')) || 1;
      var unknown_device = this.get('unknown_device');

      if (!status) {
        return Ember.RSVP.reject({
          errors: [{
            error: "Select a status"
          }]
        });
      }

      if (!owner) {
        return Ember.RSVP.reject({
          errors: [{
            error: "Select an owner"
          }]
        });
      }

      if (!device && !unknown_device || device && unknown_device) {
        return Ember.RSVP.reject({
          errors: [{
            error: "Select a device or check 'Unknown Device'"
          }]
        });
      }

      var notes = this.get('notes');

      if (!notes) {
        return Ember.RSVP.reject({
          errors: [{
            error: "Enter a note"
          }]
        });
      }

      var _this$getProperties = this.getProperties('contactName', 'contactPhone', 'contactEmail', 'tracking', 'return_tracking', 'replacement_device', 'stk', 'billing_task'),
          contactName = _this$getProperties.contactName,
          contactPhone = _this$getProperties.contactPhone,
          contactEmail = _this$getProperties.contactEmail,
          tracking = _this$getProperties.tracking,
          return_tracking = _this$getProperties.return_tracking,
          replacement_device = _this$getProperties.replacement_device,
          stk = _this$getProperties.stk,
          billing_task = _this$getProperties.billing_task;

      if (!(contactName || contactPhone || contactEmail)) {
        return Ember.RSVP.reject({
          errors: [{
            error: "Enter a contact information"
          }]
        });
      }

      return store.adapterFor('application').createSupportCase({
        client: owner.id,
        device: device,
        notes: notes,
        status: status,
        files: this.get('files').filter(function (f) {
          return !!f.get('url');
        }).mapBy('url').join(','),
        contact_name: contactName,
        contact_phone: contactPhone,
        contact_email: contactEmail,
        tracking: tracking,
        return_tracking: return_tracking,
        replacement_device: replacement_device,
        stk: stk,
        billing_task: billing_task
      });
    }
  });

  var _default = _routeComponent.default.extend({
    templateEdit: 'components/forms/create-support-case',
    isPending: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('status', this.store.peekAll('support-case-status'));
      var supportCase = SupportCase.create();

      if (this.data.owner) {
        supportCase.set('owner', this.data.owner);
      }

      if (this.data.device) {
        supportCase.set('device', this.data.device.id);
      }

      supportCase.set('status', this.get('status').findBy('id', '1'));
      this.set('supportCase', supportCase);
    },
    submitSupportCase: function submitSupportCase() {
      var _this = this;

      this.set('isPending', true);
      this.get('flashes').clear();
      this.get('supportCase').save(this.get('store')).then(function () {
        var flashes = _this.get('flashes');

        var succFlash = {
          alert: 'Support case has been sent!'
        };
        flashes.pushObject(succFlash);
        Ember.run.later(function () {
          if (flashes) {
            flashes.removeObject(succFlash);
          }
        }, 10000);
      }).catch(function (xhr) {
        if (xhr && xhr.errors) {
          var flashes = _this.get('flashes');

          var errFlashes = xhr.errors;
          flashes.pushObjects(errFlashes);
          Ember.run.later(function () {
            if (flashes) {
              flashes.removeObjects(errFlashes);
            }
          }, 10000);
        } else {
          throw xhr;
        }
      }).finally(function () {
        return _this.set('isPending', false);
      });
    },
    ownerContacts: Ember.computed('supportCase.owner.id', function () {
      return this.get('supportCase.owner.contacts');
    }),
    customContacts: Ember.computed(function () {
      return [];
    }),
    contacts: Ember.computed.union('ownerContacts', 'customContacts'),
    actions: {
      submitSupportCase: function submitSupportCase() {
        return this.submitSupportCase();
      },
      selectContact: function selectContact(contact) {
        if (contact) {
          var _contact$getPropertie = contact.getProperties('phone', 'email'),
              phone = _contact$getPropertie.phone,
              email = _contact$getPropertie.email;

          this.get('supportCase').setProperties({
            contactPhone: phone,
            contactEmail: email
          });
        }
      },
      createContact: function createContact(name) {
        if (name) {
          this.get('customContacts').pushObject(Ember.Object.create({
            displayName: name
          }));
          this.get('supportCase').setProperties({
            contactName: name,
            contactPhone: null,
            contactEmail: null
          });
        }
      },
      addFile: function addFile() {
        var _this2 = this;

        this.createUploadUrl('supportcase').then(function (_ref) {
          var upload_url = _ref.data.upload_url;
          return upload_url;
        }).then(function (uploadUrl) {
          _this2.get('supportCase.files').pushObject(Ember.Object.create({
            url: null,
            uploadUrl: uploadUrl
          }));
        });
      },
      removeFile: function removeFile(file) {
        this.get('supportCase.files').removeObject(file);
      }
    }
  });

  _exports.default = _default;
});