define("backend/pods/components/hm-model/fieldset-essentials/company-w-departments/component", ["exports", "backend/pods/components/hm-model/mixins/department-status", "backend/pods/components/hm-model/mixins/department-won-lost"], function (_exports, _departmentStatus, _departmentWonLost) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_departmentStatus.default, _departmentWonLost.default, {
    tagName: 'fieldset',
    store: Ember.inject.service(),
    actions: {
      blank: function blank() {}
    }
  });

  _exports.default = _default;
});