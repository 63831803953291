define("backend/prototypes/tables/headers/clients", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([// Client
  {
    attr: 'id',
    name: 'Id'
  }, {
    attr: 'clientNumber',
    name: 'Client Number'
  }, {
    attr: 'displayName',
    name: 'Name',
    component: 'hm-models/list/row-w-departments/name'
  }, // Person, Company
  {
    attr: 'departmentsNames',
    name: 'All Departments',
    display: false,
    style: 'whitespace-no-wrap'
  }, {
    attr: 'organization'
  }, // Distributor
  {
    modelName: 'distributor',
    attr: 'displayName',
    name: 'Distributor',
    component: 'lazy-load'
  }, {
    attr: 'departments',
    name: 'Status',
    component: 'hm-models/list/row-w-departments/status'
  }, {
    attr: 'type.displayName',
    name: 'Type'
  }, {
    attr: 'rating.displayName',
    name: 'Rating',
    display: false
  }, {
    attr: 'startDate',
    name: 'Start Date',
    format: 'date',
    display: false
  }, // Address
  {
    attr: 'address.zip',
    name: 'ZIP',
    display: false
  }, {
    attr: 'address.city',
    name: 'City'
  }, {
    attr: 'address.state',
    name: 'State'
  }, {
    attr: 'address.country',
    name: 'Country',
    display: false
  }, // Contact
  {
    attr: 'email',
    name: 'E-Mail',
    display: false
  }, {
    attr: 'phone',
    display: false
  }, // Devices
  {
    attr: 'counts.devices',
    name: 'Devices',
    display: false
  }]);

  _exports.default = _default;
});