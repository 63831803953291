define("backend/validators/department-is-offer-phase-done", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Validation = _base.default.extend({
    validate: function validate(value, options, model) {
      if (model.get('status.isOfferPhaseDone') && Ember.isEmpty(value)) {
        return 'Can\'t be blank';
      }

      return true;
    }
  });

  Validation.reopenClass({
    getDependentsFor: function getDependentsFor() {
      return ['model.status.id'];
    }
  });
  var _default = Validation;
  _exports.default = _default;
});