define("backend/components/entry-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ApiValidation = Ember.Object.extend({
    type: null,
    isValid: null,
    isPending: null,
    isNotEmpty: Ember.computed.notEmpty('isPending'),
    run: function run(container, value) {
      var _this = this;

      var adapter = Ember.getOwner(container).lookup('adapter:validation');
      this.set('isPending', true);
      return adapter.validate(this.get('type'), value).then(function (_ref) {
        var data = _ref.data;
        return _this.setProperties({
          isValid: data.validity,
          data: data
        });
      }).catch(function () {
        return _this.setProperties({
          isValid: false,
          data: null
        });
      }).finally(function () {
        return _this.set('isPending', false);
      });
    },
    clear: function clear() {
      this.setProperties({
        isPending: null,
        isValid: null,
        data: null
      });
    }
  });

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    keywords: Ember.inject.service(),
    canSave: Ember.computed('selected', 'currentUser.isAdmin', 'selected.aclIsOwner', function () {
      return this.get('currentUser').canSave(this.get('selected'));
    }),
    canDelete: Ember.computed.reads('isNotNew'),
    canCancel: true,
    saveEntryAction: 'saveEntry',
    cancelEditAction: 'cancelEdit',
    deleteEntryAction: 'deleteEntry',
    createLinkedAction: 'createLinkedEntry',
    deleteLinkedAction: 'deleteLinkedEntry',
    selectItemAction: 'selectItem',
    selectOwnerAction: 'selectOwner',
    loadLogsAction: 'loadLogs',
    gender: [Ember.Object.create({
      id: 'M',
      displayName: 'Male'
    }), Ember.Object.create({
      id: 'F',
      displayName: 'Female'
    }), Ember.Object.create({
      id: 'D',
      displayName: 'Diverse'
    })],
    paymentIntervals: [Ember.Object.create({
      id: 'M',
      displayName: 'Monthly'
    }), Ember.Object.create({
      id: 'Q',
      displayName: 'Quarter'
    }), Ember.Object.create({
      id: 'Y',
      displayName: 'Yearly'
    })],
    paymentMethods: [Ember.Object.create({
      id: 'B',
      displayName: 'Billing'
    }), Ember.Object.create({
      id: 'D',
      displayName: 'Debit'
    })],
    phonePlaceholder: '+43 664 12 34 56 78',
    isNew: Ember.computed.alias('selected.isNew'),
    isNotNew: Ember.computed.not('isNew'),
    buffers: Ember.computed.alias('selected.buffers'),
    classSaveButton: Ember.computed('selected.hasJustSaved', 'selected.hasBufferedChanges', function () {
      if (this.get('selected.hasJustSaved')) {
        return 'btn-primary btn-success';
      } else if (this.get('selected.hasBufferedChanges')) {
        return 'btn-primary';
      }

      return 'btn-primary style-primary-disabled';
    }),
    supportsVATValidation: true,
    runValidations: Ember.observer('buffers.selected.id', function () {
      if (!this.get('supportsVATValidation')) {
        return false;
      }

      var selectedVAT = this.get('buffers.selected.vat');
      this.runValidationVAT(selectedVAT);
    }),
    runValidationVAT: function runValidationVAT(value) {
      var validation = this.get('apiValidations.vat') || ApiValidation.create({
        type: 'vat'
      });

      if (!this.get('apiValidations')) {
        this.set('apiValidations', Ember.Object.create({
          vat: validation
        }));
      }

      if (Ember.isBlank(value)) {
        validation.clear();
      } else {
        validation.run(this, value);
      }
    },
    actions: {
      saveEntry: function saveEntry() {
        return this.sendAction('saveEntryAction');
      },
      cancelEdit: function cancelEdit() {
        return this.sendAction('cancelEditAction');
      },
      deleteEntry: function deleteEntry() {
        return this.sendAction.apply(this, ['deleteEntryAction'].concat(Array.prototype.slice.call(arguments)));
      },
      createLinkedEntry: function createLinkedEntry(path) {
        return this.sendAction('createLinkedAction', path);
      },
      deleteLinkedEntry: function deleteLinkedEntry(entry) {
        return this.sendAction('deleteLinkedAction', entry);
      },
      selectItem: function selectItem(item, id, formObject) {
        return this.sendAction('selectItemAction', item, id, formObject);
      },
      selectOwner: function selectOwner(item, id, formObject) {
        return this.sendAction('selectOwnerAction', item, id, formObject);
      },
      loadLogs: function loadLogs() {
        return this.sendAction('loadLogsAction');
      },
      createSource: function createSource(value) {
        return this.sendAction('createLinkedAction', 'source', value);
      },
      createTipster: function createTipster(value) {
        return this.sendAction('createLinkedAction', 'tipster', value);
      },
      createContactType: function createContactType(value, model) {
        return this.sendAction('createLinkedAction', 'contact-type', value, model);
      },
      createOrganization: function createOrganization(value) {
        return this.sendAction('createLinkedAction', 'organization', value);
      },
      createReason: function createReason(value) {
        return this.sendAction('createLinkedAction', 'reason', value);
      },
      createLabel: function createLabel(value, component) {
        return this.sendAction('createLinkedAction', 'label', value, component);
      },
      createContact: function createContact(value) {
        return this.sendAction('createLinkedAction', 'contact', value);
      },
      setStartDate: function setStartDate(value) {
        return this.sendAction('createLinkedAction', 'start-date', value);
      },
      setEndDate: function setEndDate(value) {
        return this.sendAction('createLinkedAction', 'end-date', value);
      },
      saveEvent: function saveEvent() {
        return this.sendAction('saveEventAction');
      },
      validateVAT: function validateVAT(value) {
        this.runValidationVAT(value);
      }
    }
  });

  _exports.default = _default;
});