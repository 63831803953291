define("backend/utils/static-data/department-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [// Client Department Status
  {
    id: 1,
    name: 'In Preparation',
    marker_prefix: 80,
    is_client_status: true,
    is_client_in_preparation: true,
    needs_num_of_devices: true
  }, {
    id: 2,
    name: 'Active',
    marker_prefix: 80,
    is_client_status: true,
    is_client_active: true
  }, {
    id: 3,
    name: 'Cancelled',
    is_client_status: true,
    is_client_cancelled: true
  }, // Lead Department Status
  {
    id: 11,
    name: 'Cold Lead',
    is_lead_status: true,
    marker_prefix: 160,
    is_active: true,
    is_open: true,
    is_cold_lead: true
  }, {
    id: 12,
    name: 'Relevant Stakeholder Contacted',
    is_lead_status: true,
    marker_prefix: 160,
    is_active: true,
    is_open: true,
    is_contacted: true
  }, {
    id: 13,
    name: 'Interest Expressed',
    is_lead_status: true,
    marker_prefix: 200,
    is_active: true,
    is_interest_expressed: true
  }, {
    id: 15,
    name: 'Demo Planned',
    is_lead_status: true,
    marker_prefix: 240,
    is_active: true,
    is_demo_planned: true
  }, {
    id: 14,
    name: 'Demo Done',
    is_lead_status: true,
    marker_prefix: 240,
    is_active: true,
    is_demo_done: true,
    allow_probability: true
  }, {
    id: 17,
    name: 'Offer Phase Done',
    is_lead_status: true,
    marker_prefix: 120,
    is_active: true,
    is_offer_phase_done: true,
    allow_probability: true,
    requires_next_step: true
  }, {
    id: 18,
    name: 'Won',
    is_lead_status: true,
    is_active: true,
    is_won: true,
    is_selectable: false
  }, {
    id: 20,
    name: 'Lost',
    is_lead_status: true,
    is_active: false,
    is_lost: true,
    is_selectable: false
  }, // Sponsored Client Department Status
  {
    id: 31,
    name: 'In Preparation',
    marker_prefix: 80,
    is_sponsored_client_status: true,
    is_sponsored_client_in_preparation: true,
    needs_num_of_devices: true
  }, {
    id: 32,
    name: 'Active',
    marker_prefix: 80,
    is_sponsored_client_status: true,
    is_sponsored_client_active: true
  }, {
    id: 33,
    name: 'Cancelled',
    is_sponsored_client_status: true,
    is_sponsored_client_cancelled: true
  }];
  _exports.default = _default;
});